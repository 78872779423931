<template>
  <div class="website-container">
    <h1 style="text-align:center; font-size:25px; padding: 15px;">TEAM DASHBOARD</h1>
    <div class="generalInfo">
      <div class="Picture">
        <span class="team-icon"
          v-bind:style="{'background-image': `url('${$bucketUrl}/images/teams/${Team_id}.png')`}"></span>
      </div>
      <div class="Name">
        <p>{{First_Name}}</p>
      </div>
    </div>


    <div class="registrations">

      <h1 style="text-align:center; font-size:20px; padding: 15px;">Registration</h1>
      <select v-model="selectedRegistration"
        @change="getDivisions(selectedRegistration.league_id,selectedRegistration.season_id)" @click="getRegistrations"
        class="selection">
        <option class="option" selected disabled value="">Select Registration</option>
        <option v-for="(registration, index) in registrations" v-bind:key="index" v-bind:value="registration"
          class="option">{{registration.seasonName}} - {{registration.leagueName}}</option>
      </select>

      <select v-if="selectedRegistration" v-model="selectedDivision" @change="showPrice = true; lookRegistration()"
        class="selection">
        <option class="option" selected disabled value="">Select Division</option>
        <option class="option" v-for="(division,index) in divisions" v-bind:key="index" v-bind:value="division"
          :disabled="division.Teams.length == selectedRegistration.team_limit"
          :class="[division.Teams.length == selectedRegistration.team_limit ? 'closed' : 'open']">{{division.Name}} -
          {{division.Teams.length}}/{{selectedRegistration.team_limit}}</option>
      </select>

      <div v-if="showPrice" class="paymentInfo">
        <p>Payed by: <b>{{selectedRegistration.payed_by}}</b></p>
        <p>Price: <b>${{selectedRegistration.price}}</b></p>
        <p>Player Limit: <b>{{selectedRegistration.player_limit}}</b></p>
      </div>

      <button class="Button" 
      :disabled="!showPrice"
      @click="!alreadyRegistered ? payment=true : message = 'Already registered for this season' ">
      REGISTER
      </button>
      <p style="text-align: center">{{message}}</p>

      <Payment v-if="payment == true" 
      :selectedRegistration="selectedRegistration" 
      :Client_id="Team_id" 
      :selectedDivision="selectedDivision"
      :playerPage="false"
      @updateState="updateDataState"
      @getTeamRegistrations="getTeamRegistrations"/>

    </div>


    <div class="invitations">
      <h1 style="text-align:center; font-size:20px; padding: 15px;">Invite Players to Roster</h1>

      <select v-model="selectedRegistrationForInvite"
        @change="() => { this.getRosterPlayers(); this.getInvitedPlayers();}" class="selection">
        <option class="option" selected disabled value="">Select Registration</option>
        <option class="option" v-for="(registration, index) in teamRegistrations" v-bind:key="index"
          v-bind:value="registration">{{registration.leagueName}} - {{registration.seasonName}}</option>
      </select>

      <div class="cards" v-if="selectedRegistrationForInvite">

        <div v-for="(obj,index) in rosterPlayers" v-bind:key="index" class="playerCard">
          <p class="invite"> {{obj.Player[0].First_Name}} {{obj.Player[0].Last_Name}}</p>
        </div>

        <div v-for="(obj,index) in invitedPlayers" v-bind:key="index" class="playerCard">
          <p class="invite">Invited {{obj.Player[0].First_Name}} {{obj.Player[0].Last_Name}}</p>
        </div>

        <div v-for="index in 5" v-bind:key="index" class="playerCard" @click="clicked = true">
          <p class="plus">+</p>
        </div>

      </div>

      <div class="popup" v-if="clicked == true">
        <div class="background" @click="() => {clicked = false; selectedPlayer = '';}">
        </div>
        <div class="box">
          <SearchPlayer @search-function="selectedPlayer = $event" />
          <p>SELECTED: <b>{{selectedPlayer.First_Name}} {{selectedPlayer.Last_Name}}</b></p>
          <button class="Button" :disabled="!selectedPlayer" @click="invite">INVITE</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import jwtDecode from 'jwt-decode'
  import axios from "axios";
  import SearchPlayer from "@/components/home/SearchPlayer.vue";
  import Payment from "@/components/Payment.vue"

  export default {
    components: {
      SearchPlayer,
      Payment
    },
    data() {

      return {
        First_Name: '',
        Team_id: '',
        registrations: [],
        selectedRegistration: '',
        selectedDivision: '',
        message: '',
        divisions: [],
        showPrice: false,
        selectedRegistrationForInvite: '',
        teamRegistrations: [],
        clicked: false,
        selectedPlayer: '',
        invitedPlayers: [],
        rosterPlayers: [],
        payment: false,
        alreadyRegistered: false,
        playerRegistrations: []
      }
    },
    methods: {
      async getRegistrations() {
        axios.get(this.$apiUrl + '/getRegistrations').then(res => {
          this.registrations = res.data;
        });
      },
      async getPlayerRegistrations() {
        axios.get(this.$apiUrl + '/getPlayerRegistrations').then(res => {
          this.playerRegistrations = res.data;
        });
      },
      async getTeamRegistrations() {
        axios.get(this.$apiUrl + '/getTeamRegistrations?Team_id=' + this.Team_id).then(res => {
          this.teamRegistrations = res.data;
        });
      },
      async getDivisions(league_id, season_id) {
        axios.get(this.$apiUrl + '/getDivisionsForRegistration?league_id=' + league_id + "&season_id=" + season_id)
          .then(res => {
            this.divisions = res.data;
          });
      },
      updateDataState(payment){
        this.payment = payment;
      },
      async lookRegistration() {
        for (let reg of this.teamRegistrations) {
          if (reg.Season_id == this.selectedRegistration.season_id) {
            this.alreadyRegistered = true;
          }
        }
      },
      async getInvitedPlayers() {
        axios.get(this.$apiUrl + '/getInvitedPlayers?Team_id=' + this.Team_id + '&Registration_id=' + this
          .selectedRegistrationForInvite.Registration_id).then(res => {
          this.invitedPlayers = res.data;
        });
      },
      async getRosterPlayers() {
        axios.get(this.$apiUrl + '/getRosterPlayers?Team_id=' + this.Team_id + '&Registration_id=' + this
          .selectedRegistrationForInvite.Registration_id).then(res => {
          this.rosterPlayers = res.data;
        });
      },

      async invite() {

        await axios.post(this.$apiUrl + '/invitePlayer', {
          League_id: this.selectedRegistrationForInvite.League_id,
          Season_id: this.selectedRegistrationForInvite.Season_id,
          Registration_id: this.selectedRegistrationForInvite.Registration_id,
          Team_id: this.Team_id,
          Player_id: this.selectedPlayer._id
        }, {
                        headers: {
                            'token': `${localStorage.userToken}`
                        }
                    }).then(async res => {
          this.message = res.data;
        })

        this.clicked = false;
        this.selectedPlayer = '';
        this.getInvitedPlayers();

      },

    },
    created() {
      if (localStorage.userToken) {
        let token = localStorage.userToken;
        let decoded = jwtDecode(token);
        this.First_Name = decoded.First_Name;
        this.Team_id = decoded.Team_id;
      }
      this.getRegistrations();
      this.getTeamRegistrations();
      this.getPlayerRegistrations();
    },
  }
</script>

<style lang="scss" scoped>
  .generalInfo {
    display: flex;
  }

  .playerPicture {
    height: 200px;
    width: 200px;
  }

  .Name {
    display: flex;
    flex-direction: column;
    font-size: 25px;

    p {
      text-transform: uppercase;
      letter-spacing: 4px;
      padding: 5px;
    }

    p:first-child {
      margin-top: 25%;
    }
  }

  .registrations {
    height: 450px;
    width: 80%;
    margin-left: 10%;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.589);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .invitations {
    width: 80%;
    margin-left: 10%;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.589);
    margin-top: 50px;
    margin-bottom: 50px;

  }

  .cards {
    display: flex;
    flex-wrap: wrap;
  }

  .selection {
    border: 0;
    padding: 8px 0;
    border-bottom: 1px solid rgb(66, 66, 66);
    width: 450px;
    height: 37px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Button {
    width: 250px;
    height: 40px;
    margin-top: 30px;
    padding: 5px 50px 5px 50px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 15px;
    letter-spacing: 1px;
    border: 0px solid;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
    cursor: pointer;
  }

  .Button:disabled {
    background-color: rgb(131, 131, 131);
  }

  .option {
    font-weight: 600;
  }

  .closed {
    color: red;
  }

  .open {
    color: black;
  }

  .paymentInfo {
    display: block;
    width: 50%;
    margin-left: 26.5%;
    margin-top: 30px;
    font-size: 16px;
  }

  .playerCard {
    width: 15%;
    height: 200px;
    margin: 2.5%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.329);
    cursor: pointer;
  }

  .plus {
    font-size: 80px;
    text-align: center;
    line-height: 190px;
    color: rgba(0, 0, 0, 0.801);
  }

  .popup {
    background-color: #000000b6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .box {
      width: 40%;
      height: auto;
      z-index: 1010;
      box-shadow: #00000077 0 3px 7px;
      border: #555;
      background-color: white;
    }

    .background {
      background-color: #1d1d1d81;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1005;
    }
  }

  .invite {
    margin-top: 50%;
    text-align: center;
    color: rgba(0, 170, 0, 0.801);
    padding: 5px;
  }

</style>