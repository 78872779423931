<template>
  <div class="search-player">
    <input
      type="search"
      :placeholder="$t('home.findPlayer')"
      :value="query"
      @input="onInput"
    />
    <ul class="list" v-show="expanded">
      <li
        v-for="(item,index) in result"
        :key="index"
        @click="$emit('search-function', item)"
      ><b>{{item.Last_Name}}, {{item.First_Name}}</b> <p v-if="item.Last_Team" style="display: inline;">| {{item.Last_Team}}</p></li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      query: "",
      result: [],
      expanded: false
    };
  },
  methods: {
    onInput(ev) {
      this.query = ev.target.value;
      if (this.query.length >= 3) {
        this.expanded = true;
        window.addEventListener("click", this.close);
        axios
          .get(this.$apiUrl + "/search/players?playerName=" + this.query)
          .then(res => {
            this.result = res.data;
          });
      } else {
        this.expanded = false;
        this.result = [];
        window.removeEventListener("click", this.close);
      }
    },
    close(){
        this.expanded = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.search-player {
  margin-bottom: 15px;
  position: relative;
  input[type="search"] {
    width: 100%;
    font-size: 20px;
    padding: 10px;
  }
  .list {
    position: absolute;
    width: 100%;
    background-color: #eee;
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
    li {
      list-style: none;
      padding: 10px;
    }
    li:hover {
      background-color: #ddd;
    }
  }
}
</style>